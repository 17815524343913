import { documentBillDetail } from '@/api/modular/fileStream/documentManage'
import { mapState } from 'vuex'
// eslint-disable-next-line camelcase
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

export const initWx = {
  created() {
    init_wx()
  }
}
export const billDetail = {
  data() {
    return {
      files: [],
      billDetailInfo: {
        documentNodeList: [],
        approvalStatus: 0,
        recipientStatus: 0,
        acceptList: []
      },
      myData: {},

    }
  },
  mounted() {
    this.getInfo(this.$route.query.id)
  },
  methods: {
    getInfo(id) {
      this.form_id = id
      this.getBillDetail(id).then((res) => {
        const { documentFileList, form, billDetailInfo } = res
        this.files = documentFileList
        this.myData = form
        this.billDetailInfo = billDetailInfo
      })
    },
   
  }
}
export default {
  computed: {
    ...mapState({
      flow_secrecy_degree: state => state.mobile.flow_secrecy_degree,
      flow_urgency_degree: state => state.mobile.flow_urgency_degree,
      flow_file_type: state => state.mobile.flow_file_type,
      flow_approval_status: state => state.mobile.flow_approval_status
    })
  },
  methods: {
    apiBillDetail(id) {
      return documentBillDetail({ id })
    },
    formatDraftId(id) {
      if (!id) return []
      return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
    },
    getBillDetail(id) {
      return documentBillDetail({ id }).then((res) => {
        const {
          acceptTime,
          name,
          urgencyDegree,
          agency,
          secrecyDegree,
          deviseOpinion,
          draftId,
          approvalWay,
          title,
          fileType,
          summary,
          documentNodeList,
          documentFileList,
          content,
          templateName,
          documentNumber,
          mainFile,
          tellPhone,
          serialNumber,
          draftCompany,
          receiverCompany,
          meetCompany,
          meetDesc,
          outDraftCompany,
          outDraftDesc,
          checkIds
        } = res.data
        const form = {
          swsj: acceptTime,
          name,
          jjcd: urgencyDegree ? String(urgencyDegree) : '',
          miji: secrecyDegree ? String(secrecyDegree) : '',
          lwjg: agency, 
          nbyj: deviseOpinion,
          summary,
          templateName,
          spfs: '1' || String(approvalWay),
          title,
          xzfn: String(fileType),
          nodes: documentNodeList,
          fileIds: documentFileList,
          content,
          documentNumber,
          tellPhone,
          attach: mainFile?[mainFile]:[], //正文
          mainFile,
          fileIds: documentFileList, //附件
          serialNumber,
          draftCompany: this.formatDraftId(draftCompany),
          draftId: this.formatDraftId(draftId),
          checkIds: this.formatDraftId(checkIds),
          receiverCompany,
          meetCompany,
          meetDesc,
          outDraftCompany,
          outDraftDesc,
        }
        return {
          documentFileList,
          form,
          billDetailInfo: res.data
        }
      })
    },

  },
  created() {
    if (!this.flow_secrecy_degree || this.flow_secrecy_degree.length === 0) {
      this.$store.dispatch('mobile/FlowSecrecyDegree')
      this.$store.dispatch('mobile/FlowUrgencyDegree')
      this.$store.dispatch('mobile/FlowFileDegree')
      this.$store.dispatch('mobile/FlowApprovalStatus')
    }
  }
}
